@import "../variables/variables.scss";

.wrapper {
  background-color: $dark;
  padding: 40px 20px;

  border-bottom: 1px solid white;
}

.nav_center {
  // display: grid;
  // grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));

  // gap: 20px;

  display: flex;
  flex-direction: row;
  justify-content: space-around;

  list-style: none;

  .link {
    color: white;
    font-size: 25px;
    cursor: pointer;
    margin-bottom: 15px;
    &:hover {
      opacity: 0.8;
    }
  }
}

.dropdown_menu {
  margin-bottom: 15px;
  p {
    color: white;
    opacity: 0.5;
    margin-top: 10px;
    cursor: pointer;

    &:hover {
      opacity: 1;
    }
  }
}

.contacts {
  h4 {
    color: white;
    opacity: 0.5;

    font-size: 18px;
    letter-spacing: 2px;
    margin-top: 40px;
  }

  p {
    color: white;
    opacity: 0.5;
    margin-top: 30px;
    cursor: pointer;
    &:hover {
      opacity: 1;
    }
  }
}

.footer_bottom {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  padding: 0 40px;
  margin-bottom: 50px;
  margin-top: 60px;

  &_left {
    p {
      color: $lightblue;
      cursor: pointer;
      font-size: 19px;
      margin-bottom: 7px;
      &:hover {
        opacity: 0.8;
      }
    }
  }
}

.icons {
  color: white;
  cursor: pointer;

  .icon {
    margin-left: 20px;
    &:hover {
      opacity: 0.8;
    }
  }
}

.copyright {
  background-color: $dark;
  padding: 40px 0;
  p {
    color: grey;
    text-align: center;
  }
}

@media only screen and (max-width: 740px) {
  .nav_center {
    display: flex;
    flex-direction: column;
  }

  .footer_bottom {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0;
    .icons {
        margin-top: 10px;
    }
  }

}
