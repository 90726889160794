.wrapper {
    background: url("../assets/fish_back.jpg");
  
    position: relative;
    //   display: flex;
    //   flex-direction: column;
    //   justify-content: space-between;
    background-repeat: no-repeat;
    background-position: center center;
  
    background-size: cover;
    background-color: grey;
    height: 140vh;
  
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(320px, 40%));
    gap: 40px;
    justify-content: center;
  
    margin-bottom: 60px;
  }
  
  .left {
      h1 {
        color: white;
        margin-top: 50px;
        font-size: 25px;
      }
    }
    
    .right {
      border: 1px solid white;
      margin-top: 100px;
      margin-bottom: 150px;
      padding: 20px 60px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    
    
      p {
        color: white;
        text-align: center;
        font-size: 14px;
        line-height: 20px;
      }
    
      button {
        color: white;
        font-family: "Gilroy";
        font-size: 14px;
        padding: 15px 50px;
        border: none;
        outline: 1px solid white;
        background: transparent;
        letter-spacing: 1px;
        cursor: pointer;
    
        margin-top: 80px;
    
        transition: 0.3s;
        &:hover {
          background: white;
          color: black;
        }
      }
    }