@import "../variables/variables.scss";

.wrapper {
  margin-top: 40px;
  margin-bottom: 65px;
  //padding: 20px;

  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(320px, 40%));
  //grid-template-columns: 300px 1fr;
  gap: 20px;

  border-bottom: 1px solid grey;
  padding-bottom: 80px;

  justify-content: center;
}

.header {
  font-size: 14px;
  margin-left: -20px;
}

.ul {
  display: flex;
  flex-direction: column;
  justify-content: center;
  //margin-left: 20px;
  margin-top: 55px;
}

.li_result {
  cursor: pointer;
  list-style: none;
  margin-bottom: 10px;

  position: relative;

}

.active {
  color: $lightblue;
  &::before {
    content: "";
    background: $lightblue;
    position: absolute;
    // top: calc(100% + 10px);
    left: -25px;
    right: 10px;
    bottom: 10px;
    height: 1px;
    width: 20px;
  }
}

.count {
  display: none;
}

.active_count {
  display: block;
  color: $lightblue;
}

.right_result {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  h1 {
    font-size: 110px;
  }
  h2 {
    font-size: 30px;
  }
}

@media only screen and (max-width: 480px) {
  .right_result {
    h1 {
      font-size: 60px;
    }
    h2 {
      font-size: 20px;
    }
  }
}
