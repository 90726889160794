.wrapper {
  background: url("../assets/bgs.jpg");

  position: relative;
  //   display: flex;
  //   flex-direction: column;
  //   justify-content: space-between;
  background-repeat: no-repeat;
  background-position: center center;

  background-size: cover;
  background-color: grey;
  height: 140vh;

  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(340px, 40%));
  gap: 40px;
  justify-content: center;

  margin-bottom: 60px;
}

.left {
    h1 {
      font-size: 25px;
      margin-top: 50px;
    }
  }
  
  .right {
    border: 1px solid black;
    margin-top: 100px;
    margin-bottom: 150px;
    padding: 20px 60px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  
  
    p {
      text-align: center;
      font-size: 14px;
      line-height: 20px;
    }
  
    button {
      font-family: "Gilroy";
      font-size: 14px;
      padding: 15px 50px;
      border: none;
      outline: 1px solid black;
      background: transparent;
      letter-spacing: 1px;
      cursor: pointer;
  
      margin-top: 80px;
  
      transition: 0.3s;
      &:hover {
        background: white;
        color: black;
      }
    }
  }
