@import "../variables/variables.scss";

@font-face {
  src: url("../assets/fonts/Gilroy-UltraLight.ttf");
  font-family: "Gilroy-UltraLight";
}

@font-face {
  src: url("../assets/fonts/Gilroy-Thin.ttf");
  font-family: "Gilroy-Thin";
}

.article_bottom {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.news_header_right {
  display: flex;
  flex-direction: row;
  align-items: center;
  text-align: center;
  margin-right: 10px;
  cursor: pointer;
  color: $lightblue;

  .news_arrow {
    transition: transform 0.25s ease-in-out;
    margin-top: 3px;
  }

  p {
    margin-right: 10px;
    font-size: 14px;
  }
}

.form {
  margin-bottom: 45px;
  padding: 20px;

  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
  gap: 40px 20px;

  
}

.article {
  //outline: 1px solid black;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  //padding: 10px;
  cursor: pointer;
  &:hover {
    img {
      transform: scale(1.1);
    }
    .news_arrow {
      transform: translateX(8px);
    }
  }

  .article_date {
    font-family: "Gilroy-UltraLight";
    font-size: 15px;
    margin-bottom: 14px;
  }

  .article_text {
    font-size: 15px;
    margin-bottom: 15px;
  }

  h3 {
    margin-bottom: 10px;
    letter-spacing: 1px;
    cursor: pointer;
    &:hover {
      opacity: 0.8;
    }
  }

  .img_wrapper {
    overflow: hidden;
    margin-bottom: 1rem;
    height: 281px;

    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
      transition: all 0.4s ease-in-out;
      cursor: pointer;
    }
  }
}

@media only screen and (max-width: 480px) {
  .form {
    margin-bottom: 45px;
    padding: 20px;

    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 40px 20px;
  }
}


@media only screen and (min-width: 1400px) {
  .form {
    width: 1400px;
    margin: 0 auto;
  }
}