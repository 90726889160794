@import "../variables/variables.scss";

.cards {
  margin: 80px 0;
  padding: 40px;

  // width: 100%;

  // display: grid;
  // //flex-direction: row;
  // grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
  // gap: 20px;

  // overflow: hidden;
  //     display: flex;
  //     flex-direction: row;

  //     // width: 100vw;
  //     // overflow: hidden;

  // //     transition: translate;
  // //   transition-property: transform;
  // //   transition-duration: 300ms;
  // //   transition-timing-function: ease-in-out;
  // //   margin-bottom: 2rem;
}

// .slick-slide { /* spacing in between each slide */
//     .card {
//         margin: 20px -10px;
//     }

// }

// .slick-list { /* spacing in between the group of slides */

//         margin: 20px -10px;

// }

.card {
  min-height: 200px;

  display: flex;
  flex-direction: column;
  justify-content: space-around;

  border: 1px solid grey;
  cursor: pointer;
  overflow: hidden;

  padding: 30px;

  position: relative;
  transition: color 0.25s ease;

  p {
    color: gray;
    margin-top: 20px;
  }

  h3 {
    margin-bottom: 40px;
  }

  &:hover {
    h3,
    p {
      color: white;
    }
    .break {
      background-color: white;
    }
  }
  &:after {
    position: absolute;
    content: "";
    top: 0;
    right: 0;
    width: 0;
    height: 100%;
    background-color: $lightblue;
    transform-origin: right;
    transition: width 0.25s ease;
    z-index: -1;
  }

  &:hover:after {
    width: 100%;
  }
}

.break {
  width: 30px;
  height: 3px;
  background-color: $lightblue;
  margin-top: 20px;
}

// .swiper-button-next {

//   background-color: red;
//   width: 40px;
//   transform: translateY(-300%);
// }
// .swiper-button-prev {
//   background-color: red;
//   display: none;
//   visibility: hidden;
// }

.swiper {
  width: 100%;
  height: 250px;
}

.swiper-slide {
  //text-align: center;
  // font-size: 18px;
  // background: #fff;

  /* Center slide text vertically */

  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: flex-start;
}

// .swiper-slide img {
//   display: block;
//   width: 100%;
//   height: 100%;
//   object-fit: cover;
// }

// .swiper-container {
//   width: 480px;
// }

// @media screen and (min-width: 640px) {
//   .swiper-container {
//     width: 640px;
//   }
// }

// @media screen and (min-width: 1024px) {
//   .swiper-container {
//     width: 1024px;
//   }
// }

@media only screen and (min-width: 1400px) {
  .cards {
    width: 1400px;
    margin: 0 auto;
  }
}
