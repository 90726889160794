.wrapper {
  background: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
    url("../assets/talking_man.jpg");

  position: relative;
  //   display: flex;
  //   flex-direction: column;
  //   justify-content: space-between;
  background-repeat: no-repeat;
  background-position: center center;

  background-size: cover;
  background-color: grey;
  height: 140vh;

  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(340px, 40%));
  gap: 40px;
  justify-content: center;

  
}

.left {
  h1 {
    color: white;
    margin-top: 50px;
  }
}

.right {
  border: 1px solid white;
  margin-top: 100px;
  margin-bottom: 150px;
  padding: 20px 60px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;

  img {
    width: 250px;
  }

  p {
    text-align: center;
    color: white;
    font-size: 14px;
    line-height: 20px;
  }

  button {
    color: white;
    font-family: "Gilroy";
    font-size: 14px;
    padding: 15px 50px;
    border: none;
    outline: 1px solid white;
    background: transparent;
    letter-spacing: 1px;
    cursor: pointer;

    margin-top: 20px;

    transition: 0.3s;
    &:hover {
      background: white;
      color: black;
    }
  }
}
