@font-face {
    src: url("../assets/fonts/Gilroy-Regular.ttf");
    font-family: 'Gilroy'; 
}

@font-face {
    src: url("../assets/fonts/Gilroy-UltraLight.ttf");
    font-family: 'Gilroy-UltraLight';
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    scroll-behavior: smooth;

    -webkit-tap-highlight-color: transparent;

    font-family: 'Gilroy';
  }