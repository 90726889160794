@import "../variables/variables.scss";

@font-face {
  src: url("../assets/fonts/Gilroy-UltraLight.ttf");
  font-family: "Gilroy-UltraLight";
}

@font-face {
  src: url("../assets/fonts/Gilroy-Thin.ttf");
  font-family: "Gilroy-Thin";
}

.header {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-repeat: no-repeat;
  background-position: center center;
  //background-attachment: fixed;
  background-size: cover;
  background-color: grey;
  height: 100vh;
}

.one {
  background: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.3)),
    url("../assets/1.jpg");
  background-repeat: no-repeat;
  background-size: cover;
}

.two {
  background: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.3)),
    url("../assets/2.jpg");
  background-repeat: no-repeat;
  background-size: cover;
}
.three {
  background: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.3)),
    url("../assets/3.jpg");
  background-repeat: no-repeat;
  background-size: cover;
}
.four {
  background: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.3)),
    url("../assets/4.jpg");
  background-repeat: no-repeat;
  background-size: cover;
}

.five {
  background: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.3)),
    url("../assets/5.jpg");
  background-repeat: no-repeat;
  background-size: cover;
}

.nav {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  padding: 60px 0;
  font-size: 16px;

  // position: sticky;
  // top: 0px;
  // width: 100%;

  // z-index: 1000;
  // height: 45px;
  // background-color: black;
  h1 {
    color: white;
  }
}

.nav_right,
.nav_center {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  list-style: none;

  li {
    padding: 0 15px;
    color: white;
    p {
      display: inline-block;
      position: relative;
      cursor: pointer;
    }
    p:after {
      content: "";
      position: absolute;
      width: 100%;
      transform: scaleX(0);
      height: 1px;
      bottom: -5px;
      left: 0;
      background-color: white;
      transform-origin: bottom left;
      transition: transform 0.25s ease-out;
    }

    p:hover:after {
      transform: scaleX(1);
      transform-origin: bottom left;
      z-index: 100;
    }
  }
}

.title {
  margin-left: 200px;
}

.header_title {
  width: 200px;
  color: white;
  margin-bottom: 20px;
  h1 {
    font-family: "Gilroy-UltraLight";
    font-size: 52px;
    font-weight: 300;
  }

  h1:nth-child(1) {
    padding-left: 20px;
  }

  h1:nth-child(2) {
    padding-left: 65px;
  }
}

.text_title {
  font-family: "Gilroy-Thin";
  line-height: 20px;
  color: white;
  font-size: 14px;
  font-weight: 100;
}

.button_title {
  color: white;
  font-family: "Gilroy";
  font-size: 14px;
  padding: 15px 50px;
  border: none;
  outline: 1px solid white;
  background: transparent;
  letter-spacing: 1px;
  cursor: pointer;

  margin-top: 20px;

  transition: 0.3s;
  &:hover {
    background: white;
    color: black;
  }
}

.dropdown {
  position: relative;
  display: inline-block;

  display: flex;
  flex-direction: row;
  align-items: flex-end;

  &:hover {
    .dropdown_menu,
    .arrow_up {
      display: block;
    }
    .arrow_down {
      display: none;
    }
  }
}

.dropdown_menu {
  display: flex;
  flex-direction: column;
  width: auto;

  position: absolute;
  left: -15px;
  top: calc(100% + 1px);
  background-color: $lightblue;
  padding: 15px;

  display: none;
  z-index: 1;
  p {
    margin: 15px 0;
    display: inline-block;

    white-space: nowrap;
  }
}

.arrow_up {
  display: none;
}

.bottom_nav {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  padding: 0 80px;
  margin-bottom: 50px;
  margin-top: 60px;

  // position: absolute;
  // bottom: 30px;
}

.arrow_news {
  cursor: pointer;
  color: white;

  display: flex;
  flex-direction: row;
  align-items: center;

  a {
    text-decoration: none;
    margin-left: 5px;
    color: white;
  }
  &:hover,
  a:hover {
    opacity: 0.8;
  }
}

.icons {
  color: white;
  cursor: pointer;

  .icon {
    margin-left: 20px;
    &:hover {
      opacity: 0.8;
    }
  }
}

// .isMenu {
//   transform: translate(100%);
// }

// .toggle {
//   cursor: pointer;
//   display: flex;
//   align-items: center;
//   font-size: 2rem;
//   transition: 0.3s ease all;
//   position: relative;
//   z-index: 10000;
//   color: white;

//   &:hover {
//     color: #119395;
//   }

//   // @include breakpoint(md) {
//   //     display: none;
//   // }
// }

.overlay {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 1000;
  background: rgba(0, 0, 0, 0.5);
  opacity: 0;
  transition: 0.5s ease-in-out;

  cursor: pointer;

  &.active {
    opacity: 1;
  }
}

.sidebar {
  width: 50%;
  height: 100%;
  background-color: $lightblue;
  position: fixed;
  right: 0;
  bottom: 0;
  top: 0;

  display: flex;
  flex-direction: column;
  align-items: center;

  transform: translateX(100%);
  transition: transform 0.5s ease-in-out;
  //transition: all 2s ease;

  &.active_sidebar {
    transform: translateX(0);
    overflow: scroll;
    overflow-x: hidden;
  }
}

.container_menu {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 80%;
}

.sidebar_li {
  &_title {
    display: flex;
    flex-direction: row;
    align-items: center;

    margin-bottom: 15px;
  }

  &_body {
    margin-left: 80px;
  }
  h1 {
    color: white;
    margin-left: 20px;
    &:hover {
      opacity: 0.8;
    }
  }
  p {
    color: white;
    opacity: 0.8;
    margin: 10px 0;
    &:hover {
      opacity: 1;
    }
  }
}

.nav_center_sidebar {
  display: flex;
  flex-direction: column;
  justify-content: space-around;

  //background-color: $lightblue;

  list-style: none;
}

.dropdown_sidebar {
  display: flex;
  flex-direction: column;
}

.dropdown_menu_sidebar {
  display: flex;
  flex-direction: column;
  width: auto;

  // position: absolute;
  // left: -15px;
  // top: calc(100% + 1px);
  // background-color: $lightblue;
  // padding: 15px;

  //display: none;
  //z-index: 1;
  p {
    margin: 15px 0;
    display: inline-block;

    white-space: nowrap;
  }
}

.input_wrapper {
  width: 350px;
  position: relative;
  margin-bottom: 25px;
  align-self: center;
  .input_sidebar {
    margin-top: 100px;
    padding: 15px;
    border: none;
    background-color: transparent;
    font-size: 20px;
    border-bottom: 1px solid white;
    width: 100%;

    color: white;
    &::placeholder {
      color: white;
    }
    &:focus {
      outline: none;
    }
  }

  .input_icon {
    position: absolute;
    bottom: 10px;
    color: white;
    right: 5px;
  }
}

// .dropdown > p:hover, .dropdown_menu {
//   opacity: 1;
//   pointer-events: auto;
// }

// .link:hover + .dropdown_menu {
//   // color: black;
//   opacity: 1;
// }

// .dropdown > p:focus {
//   .dropdown_menu {
//     opacity: 1;
//     pointer-events: auto;
// }
// }

.toggle {
  z-index: 10000;

  .line1 {
    transform: rotate(-45deg) translate(-8px, 5px);
  }
  .line2 {
    opacity: 0;
  }
  .line3 {
    transform: rotate(45deg) translate(-7px, -5px);
  }
}

.toggler {
  cursor: pointer;
  &:hover {
    .line1,
    .line2,
    .line3 {
      background: #119395;
    }
  }
  div {
    width: 1.8rem;
    height: 0.2rem;
    margin: 0.4rem;
    background: white;
    transition: 0.2s ease-in;
    cursor: pointer;
  }
}

// @media only screen and (max-width: 820px) {
//   .nav_center {
//     display: none;
//   }
// }

@media only screen and (max-width: 820px) {
  .title {
    margin: 0;
    align-self: center;
    .header_title {
      h1 {
        font-size: 42px;
      }
    }
  }

  .text_title {
    display: none;
  }
  .nav_center {
    display: none;
  }
  .input_wrapper {
    width: 250px;
  }
  .nav {
    margin: 0 20px;
    justify-content: space-between;
  }
  .sidebar {
    width: 70%;
  }
}

@media only screen and (max-width: 480px) {
  .title {
    margin: 0;
    align-self: center;
    .header_title {
      h1 {
        font-size: 42px;
      }
    }
  }
  .bottom_nav {
    .arrow_news {
      display: none;
    }
  }

  .sidebar {
    width: 100%;
  }
  .eng,
  .icon_search {
    display: none;
  }

  .input_wrapper {
    width: 250px;
  }
  .nav {
    margin: 0 20px;
    justify-content: space-between;
  }
}

@media (prefers-reduced-motion: no-preference) {
  html {
    scroll-behavior: smooth;
  }
}
