$lightblue: #0071b0;
$grey: #f5f5f5;
$dark: #0f0f0f;

@mixin breakpoint($point) {
    @if $point == md {
        //768px
        @media (min-width: 48em) {
            @content;
        }
    }
}